import useSWR, { SWRConfiguration } from "swr";
import { removeAuthToken } from "../api/auth";
import { ERROR_MESSAGES } from "../constants";
import { UserState } from "../features/user/userSlice";

export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: any[]) => {
    const later = () => {
      timeout = null;
      func(...args);
    };

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const checkDoorFeatures = (doorType: string) => {
  const hasLeftGlass = doorType.includes("both") || doorType.includes("left");
  const hasRightGlass = doorType.includes("both") || doorType.includes("right");

  const hasSideGlass =
    doorType.includes("both") ||
    doorType.includes("right") ||
    doorType.includes("left");

  const hasTransom = doorType.includes("transom");
  const hasDoubleDoor = doorType.includes("double");

  return {
    hasLeftGlass,
    hasRightGlass,
    hasSideGlass,
    hasTransom,
    hasDoubleDoor,
  };
};

export type AuthApiResponse = {
  success: boolean;
  messageKey?: string;
};

export const handleAuthApiResponse = async (
  response: Response
): Promise<AuthApiResponse> => {
  if (!response.ok) {
    const errorData = await response.json();
    let messageKey = "auth-messages.an-unknown-error-occurred";

    if (errorData && errorData.messageTranslation) {
      messageKey = errorData.messageTranslation;
    } else if (errorData) {
      const firstKey: string = Object.keys(errorData)[0];
      messageKey = errorData[firstKey][0];
    }
    return { success: false, messageKey };
  }
  return { success: true };
};

export class CustomError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.name = "CustomError";
  }

  toJSON() {
    return {
      message: this.message,
      status: this.status,
      name: this.name,
    };
  }
}

export const handle401Error = (state: UserState, action: any) => {
  const error = action.payload as CustomError;
  if (error.status === 401) {
    state.isLoggedIn = false;
    state.userData = null;
    removeAuthToken();
  } else {
    state.error = error.message || ERROR_MESSAGES.UNKNOWN_ERROR;
  }
  state.isLoading = false;
};

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const useSWRFetcher = <T>(
  url: string,
  fetchOptions?: RequestInit,
  swrOptions?: SWRConfiguration
) => {
  const fetcher = (url: string) =>
    fetch(url, fetchOptions).then((res) => res.json());

  const {
    data,
    error,
    mutate,
    isValidating: isLoading,
  } = useSWR<T>(url, fetcher, swrOptions);

  return { data, error, mutate, isLoading };
};
