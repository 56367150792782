import singleLeafDoor from "../../../../assets/images/door-types/single-winged-door.png";
import singleLeafDoorTransom from "../../../../assets/images/door-types/single-winged-door-2.png";
import singleLeafDoorLeftSidePanel from "../../../../assets/images/door-types/single-winged-left-door.png";
import singleLeafDoorLeftSidePanelTransom from "../../../../assets/images/door-types/single-winged-left-door-2.png";
import singleLeafDoorBothSidePanels from "../../../../assets/images/door-types/single-winged-left-right-door.png";
import singleLeafDoorBothSidePanelsTransom from "../../../../assets/images/door-types/single-winged-left-right-door-2.png";
import singleLeafDoorRightSidePanel from "../../../../assets/images/door-types/single-winged-right-door.png";
import singleLeafDoorRightSidePanelTransom from "../../../../assets/images/door-types/single-winged-right-door-2.png";
import doubleLeafDoor from "../../../../assets/images/door-types/two-winged-door.png";
import doubleLeafDoorBothSidePanels from "../../../../assets/images/door-types/two-winged-door-2.png";
import doubleLeafDoorBothSidePanelsTransom from "../../../../assets/images/door-types/two-winged-door-3.png";

const doorTypes = [
  { icon: singleLeafDoor, text: "single-leaf-door" },
  { icon: singleLeafDoorTransom, text: "single-leaf-door-transom" },
  { icon: singleLeafDoorLeftSidePanel, text: "single-leaf-door-left-side-panel" },
  { icon: singleLeafDoorLeftSidePanelTransom, text: "single-leaf-door-left-side-panel-transom" },
  { icon: singleLeafDoorBothSidePanels, text: "single-leaf-door-both-side-panels" },
  { icon: singleLeafDoorBothSidePanelsTransom, text: "single-leaf-door-both-side-panels-transom" },
  { icon: singleLeafDoorRightSidePanel, text: "single-leaf-door-right-side-panel" },
  { icon: singleLeafDoorRightSidePanelTransom, text: "single-leaf-door-right-side-panel-transom" },
  { icon: doubleLeafDoor, text: "double-leaf-door" },
  { icon: doubleLeafDoorBothSidePanels, text: "double-leaf-door-both-side-panels" },
  { icon: doubleLeafDoorBothSidePanelsTransom, text: "double-leaf-door-both-side-panels-transom" }
];

export default doorTypes;
