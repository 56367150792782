import { EquipmentItem } from "../../../../../types";

export const spyItems: EquipmentItem[] = [
  {
    id: 1,
    src: "peephole_viewer.png",
    alt: "24-76-00217",
    label: "24-76-00217\nŠpijunka sa ekranom",
  },
  {
    id: 2,
    src: "peephole_viewer1.png",
    alt: "24-96-00067",
    label: "24-96-00067\nPLATINUM Peephole",
  },
];
