import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/ui/Button";

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-primary">404</h1>
        <p className="text-2xl text-gray-600 mt-4">{t("page-not-found")}</p>
        <p className="mt-2 mb-6 text-gray-500">
          {t("the-page-does-not-exist")}
        </p>

        <a href="/">
          <Button>{t("go-back-home")}</Button>
        </a>
      </div>
    </div>
  );
};

export default NotFound;
