import React from "react";

import EquipmentGroup from "../EquipmentGroup";
import { Equipment } from "../../../../../types";

interface HingesProps {
  handleItemSelect: (id: number, name: string, imageName: string) => void;
  selectedItem: number | null;
  grouped: {
    [key: string]: Equipment[];
  };
}

const Hinges = ({ handleItemSelect, selectedItem, grouped }: HingesProps) => {
  return (
    <div className="mt-8">
      <hr className="mb-4 border-t" />

      <EquipmentGroup
        type="hinges"
        groupName="sarke1"
        items={grouped.s1}
        selectedItem={selectedItem}
        onRadioChange={handleItemSelect}
        groupDescription=""
      />

      <hr className="my-4 border-t" />

      <EquipmentGroup
        type="hinges"
        groupName="sarke2"
        items={grouped.s2}
        selectedItem={selectedItem}
        onRadioChange={handleItemSelect}
        groupDescription=""
      />

      <hr className="my-4 border-t" />
    </div>
  );
};

export default Hinges;
