import { EquipmentItem } from "../../../../../types";

export const electromagneticReceiverItems: EquipmentItem[] = [
  {
    id: 1,
    src: "electric-door-opener.png",
    alt: "24-68-00153",
    label: "24-68-00153\nElectric Door Opener for Platinum",
  },
];
