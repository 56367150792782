export const NAVBAR_HEIGHT = 72;
export const SIDEBAR_HEADER_HEIGHT = 64;

export const ERROR_MESSAGES = {
  UNKNOWN_ERROR: "auth-messages.an-unknown-error-occurred",
  LOGIN_FAILED: "auth-messages.logged-in-unsuccessfully",
};

export const MAX_WIDTH_SIZES = {
  xs: "max-w-xs",
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-xl",
  "2xl": "max-w-2xl",
  "3xl": "max-w-3xl",
  "4xl": "max-w-4xl",
  "5xl": "max-w-5xl",
  "6xl": "max-w-6xl",
  "7xl": "max-w-7xl",
  full: "max-w-full",
};

export const MARKUPS = [
  { value: "0", label: "0 - 0%" },
  { value: "15", label: "1 - 15%" },
  { value: "20", label: "2 -20%" },
  { value: "25", label: "3 - 25%" },
  { value: "30", label: "4 - 30%" },
  { value: "35", label: "5 - 35%" },
  { value: "40", label: "6 - 40%" },
  { value: "45", label: "7 -45%" },
  { value: "50", label: "8 - 50%" },
  { value: "55", label: "9 -55%" },
  { value: "60", label: "10 - 60%" },
];
