import { useMemo } from "react";
import useSWR from "swr";

import { Equipment } from "../types";

export interface EquipmentApiResponse {
  success: boolean;
  equipment: Equipment[];
  grouped: { [key: string]: Equipment[] }
  equipmentInCategories: { [key: number]: Equipment[] };
}

export const useEquipments = (): {
  equipments: Equipment[];
  grouped: { [key: string]: Equipment[] };
  equipmentInCategories: { [key: number]: Equipment[] };
  isLoading: boolean;
  isError: boolean;
} => {
  const fetcher = (url: string) =>
    fetch(url).then((res) => {
      if (!res.ok) {
        throw new Error(`An error occurred: ${res.status}`);
      }
      return res.json();
    });

  const { data, error } = useSWR<EquipmentApiResponse>(
    `${process.env.REACT_APP_API_URL}/api/equipment`,
    fetcher
  );

  const equipments = useMemo(() => data?.equipment ?? [], [data]);
  const grouped = useMemo(() => data?.grouped ?? {}, [data]);
  const equipmentInCategories = useMemo(() => data?.equipmentInCategories ?? {}, [data]);

  return {
    equipments,
    grouped,
    equipmentInCategories,
    isLoading: !error && !data,
    isError: !!error,
  };
};
