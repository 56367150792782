import React from "react";

interface ImagePreviewProps {
  colorHex?: string | null;
  thumbnail: string | null;
  name: string | null;
  isNonRectangular?: boolean;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
  colorHex,
  thumbnail,
  name,
  isNonRectangular = false,
}) => {
  if (colorHex) {
    return (
      <div
        style={{ backgroundColor: colorHex }}
        className="w-12 h-12 rounded-md border border-primary"
      />
    );
  }

  if (thumbnail) {
    const imageUrl = `${process.env.REACT_APP_API_URL}/${
      thumbnail.startsWith("storage") ? thumbnail : `storage/${thumbnail}`
    }`;

    return (
      <div
        className={`w-12 h-12 rounded-md border border-primary flex items-center justify-center overflow-hidden ${
          isNonRectangular ? "p-0.5" : ""
        }`}
      >
        <img
          src={imageUrl}
          alt={name ? name : "image"}
          className={`w-full h-full ${
            isNonRectangular ? "object-contain" : "object-cover"
          }`}
        />
      </div>
    );
  }

  return <></>;
};

export default ImagePreview;
