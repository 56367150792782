import { useDispatch, useSelector } from "react-redux";
import { setConfigurationField } from "../../../../features/configuration/configurationSlice";
import { useColors } from "../../../../hooks/useColors";
import Loading from "../../../ui/Loading";
import Error from "../../../ui/Error";
import { Color } from "../../../../types";
import Selectable from "../../../ui/Selectable";
import Select from "../../../ui/Select";
import { RootState } from "../../../../app/store";
import { setActiveDropDownItem } from "../../../../features/sidebar/sidebarSlice";
import { useTranslation } from "react-i18next";
import Chip from "../../../ui/Chip";

const Colors = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const colorType = useSelector(
    (state: RootState) => state.sidebar.activeDropDownItem.color
  );

  const selectedPanelColorName = useSelector(
    (state: RootState) => state.configuration["panel-color-name"]
  );
  const selectedFrameColorName = useSelector(
    (state: RootState) => state.configuration["frame-color-name"]
  );

  const colorTypeOptions = [
    { value: "panel", label: t("panel-color") },
    { value: "frame", label: t("frame-color") },
  ];

  const { colorsByCategory, isLoading, isError } = useColors(colorType);

  const handleColorSelect = (color: Color | null) => {
    dispatch(
      setConfigurationField({
        field: `${colorType}-color`,
        value: color ? color.color_hex || color.thumbnail : null,
      })
    );

    dispatch(
      setConfigurationField({
        field: `${colorType}-color-name`,
        value: color ? color.color_code : null,
      })
    );

    if (colorType === "panel") {
      dispatch(
        setConfigurationField({
          field: "frame-color",
          value: color ? color.color_hex || color.thumbnail : null,
        })
      );

      dispatch(
        setConfigurationField({
          field: "frame-color-name",
          value: color ? color.color_code : null,
        })
      );
    }
  };

  const handleColorTypeChange = (value: string) => {
    dispatch(setActiveDropDownItem({ field: "color", value }));
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  return (
    <>
      <Chip classNames="mb-10">
        <h3 className=" text-2xl font-bold text-primary-green text-center">{t("choose-door-color")}</h3>
      </Chip>

      <Select
        options={colorTypeOptions}
        value={colorType}
        onChange={handleColorTypeChange}
        additionalClasses="mb-4"
      />

      {colorsByCategory &&
        Object.entries(colorsByCategory).map(
          ([category, categorizedColors]) => (
            <div key={category} className="mb-6">
              <Chip classNames="mb-3">
                <p className="font-semibold">{category}</p>
              </Chip>

              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {categorizedColors.map((color: Color) => (
                  <Selectable
                    key={color.id}
                    isSelected={
                      colorType === "panel"
                        ? selectedPanelColorName === color.color_code
                        : selectedFrameColorName === color.color_code
                    }
                    onClick={() => handleColorSelect(color)}
                  >
                    {color.color_hex ? (
                      <div
                        className="w-16 h-16 mx-auto"
                        style={{ backgroundColor: color.color_hex }}
                      />
                    ) : (
                      <img
                        className="w-16 h-16 mx-auto"
                        src={
                          `${process.env.REACT_APP_API_URL}/${
                            color.thumbnail?.startsWith("storage")
                              ? color.thumbnail
                              : `storage/${color.thumbnail}`
                          }` || ""
                        }
                        alt={color.color_code}
                      />
                    )}
                    <div className="mt-1 text-xs text-center">
                      {color.color_code}
                    </div>
                  </Selectable>
                ))}
              </div>
            </div>
          )
        )}
    </>
  );
};

export default Colors;
