import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setConfigurationField } from "../../../../features/configuration/configurationSlice";
import HTMLRenderer from "../../../HTMLRenderer";
import Chip from "../../../ui/Chip";
import Selectable from "../../../ui/Selectable";
import Loading from "../../../ui/Loading";
import Error from "../../../ui/Error";
import { RootState } from "../../../../app/store";
import useEquipmentSystems from "../../../../hooks/useEquipmentSystems";

const Systems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { systems, isLoading, error } = useEquipmentSystems();

  const currentDoorSystem = useSelector(
    (state: RootState) => state.configuration.system
  );

  if (isLoading) return <Loading />;
  if (error) return <Error message={t("error-occurred")} />;

  const handleSelectSystem = (system: string) => {
    dispatch(setConfigurationField({ field: "system", value: system }));
  };

  const shownSystems = systems?.filter((system) => system.is_shown) ?? [];

  return (
    <>
      <Chip classNames="mb-10">
        <h3 className="text-2xl font-bold text-primary-green text-center">{t("choose-door-system")}</h3>
      </Chip>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        {shownSystems.map((item) => (
          <Selectable
            key={item.id}
            isSelected={currentDoorSystem === item.name}
            onClick={() => handleSelectSystem(item.name)}
            classNames="border border-primary-green"
          >
            <h3 className="text-center text-lg font-semibold">
              {t(item.name)}
            </h3> 

            <img
              src={`${process.env.REACT_APP_API_URL}/storage/${item.thumbnail}`}
              alt={t(item.description || "")}
              className="h-24 object-cover transition-transform duration-300 hover:scale-110"
            />

            <div className="text-sm text-center">
              {<HTMLRenderer htmlContent={t(item.description || "")} />}
            </div>
          </Selectable>
        ))}
      </div>
    </>
  );
};

export default Systems;
