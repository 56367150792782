import { TFunction } from "i18next";

export const fields = (t: TFunction, mode: "create" | "update") => {
  const commonFields = [
    { name: "username", type: "text", label: t("username"), required: true },
    { name: "email", type: "email", label: t("email"), required: true },
    { name: "phone", type: "text", label: t("phone") },
    { name: "mobile_phone", type: "text", label: t("mobilePhone") },
    { name: "city", type: "text", label: t("city") },
    { name: "address", type: "text", label: t("address") },
    { name: "zip_code", type: "text", label: t("zipCode") },
    { name: "company_name", type: "text", label: t("companyName") },
    { name: "pib", type: "text", label: t("pib") },
    { name: "social_number", type: "text", label: t("socialNumber") },
    { name: "giro_account", type: "text", label: t("giroAccount") },
    { name: "contact_person", type: "text", label: t("contactPerson") },
  ];

  if (mode === "create") {
    commonFields.push(
      {
        name: "password",
        type: "password",
        label: t("password"),
        required: true,
      },
      {
        name: "confirm_password",
        type: "password",
        label: t("confirmPassword"),
        required: true,
      }
    );
  }

  commonFields.push({
    name: "logo",
    type: "file",
    label: t("logo"),
    required: false,
  });

  return commonFields;
};
