import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Chip from "../components/ui/Chip";
import Button from "../components/ui/Button";
import { useUsers } from "../hooks/useUsers";
import Table, { TableColumn } from "../components/ui/Table";
import Loading from "../components/ui/Loading";
import Error from "../components/ui/Error";
import UserActionButtons from "../components/Admin/Users/UserActionButtons";
import { User } from "../types";
import RegisterUserModal from "../components/Admin/Users/Modals/RegisterUserModal";
import EditUserModal from "../components/Admin/Users/Modals/EditUserModal";
import DeleteUserModal from "../components/Admin/Users/Modals/DeleteUserModal";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const UsersPage: React.FC = () => {
  const { t } = useTranslation();

  const { users, isLoading, isError } = useUsers();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleDeleteUser = (user: User) => {
    setSelectedUser(user);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsRegisterModalOpen(false);
    setSelectedUser(null);
  };

  const columns: TableColumn[] = [
    { header: "ID", accessor: "id", sortable: true, filterable: false },
    {
      header: "Ime kompanije",
      accessor: "company_name",
      sortable: true,
      filterable: true,
    },
    {
      header: "Korisničko ime",
      accessor: "username",
      sortable: true,
      filterable: true,
    },
    { header: "Email", accessor: "email", sortable: true, filterable: true },
  ];

  const renderActionButtons = (row: any) => (
    <UserActionButtons
      row={row}
      onEdit={handleEditUser}
      onDelete={handleDeleteUser}
    />
  );

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col align-center gap-3 p-4">
        <Chip classNames="w-fit">
          <h1 className="text-2xl font-bold">{t("users")}</h1>
        </Chip>

        <div className="flex justify-center">
          <Button
            type="button"
            onClick={() => setIsRegisterModalOpen(true)}
            icon={faUserPlus}
            className="w-fit"
          >
            {t("register-new-user")}
          </Button>
        </div>

        {isLoading ? (
          <Loading variant="primary-light" />
        ) : isError ? (
          <Error />
        ) : (
          <Table
            pagination
            striped
            columns={columns}
            data={users}
            actionButtons={renderActionButtons}
          />
        )}
      </div>

      <RegisterUserModal
        onClose={handleCloseModal}
        isOpen={isRegisterModalOpen}
      />

      {selectedUser && (
        <EditUserModal
          onClose={handleCloseModal}
          isOpen={isEditModalOpen}
          selectedUser={selectedUser}
        />
      )}

      {selectedUser && (
        <DeleteUserModal
          onClose={handleCloseModal}
          isOpen={isDeleteModalOpen}
          selectedUser={selectedUser}
        />
      )}
    </div>
  );
};

export default UsersPage;
