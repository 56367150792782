import React, { useState, ReactNode } from "react";
import Button from "../components/ui/Button";
import subNavData from "../components/Admin/SubNavData";
import AdminSystems from "../components/Admin/AdminSystems";

const AdminPanel: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<ReactNode>(
    <AdminSystems />
  );
  const [activeId, setActiveId] = useState<number>(0);

  const handleButtonClick = (component: ReactNode, id: number) => {
    setActiveComponent(component);
    setActiveId(id);
  };

  return (
    <div>
      <nav className="flex flex-wrap justify-around bg-primary py-1 border-t border-primary-light">
        {subNavData.map((item, index) => (
          <Button
            className={`text-primary-light ${
              activeId === index ? "font-semibold underline" : "no-underline"
            }`}
            variant="link"
            key={index}
            onClick={() => handleButtonClick(item.component, index)}
            disabled={item.disabled}
          >
            {item.name}
          </Button>
        ))}
      </nav>

      <div className="p-4">{activeComponent}</div>
    </div>
  );
};

export default AdminPanel;
