import React, { ChangeEventHandler } from "react";
import Chip from "../Chip";

interface ColorInputProps {
  label?: string;
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  error?: string;
  required?: boolean;
}

const ColorInput: React.FC<ColorInputProps> = ({
  label,
  name,
  onChange,
  value,
  error,
  required,
}) => {
  return (
    <div>
      {label && (
        <label htmlFor={name} className="block mb-2 text-sm font-medium w-fit">
          <Chip>
            {label} {required && <span className="text-red-500">*</span>}
          </Chip>
        </label>
      )}

      <input
        id={name}
        name={name}
        type="color"
        className={`bg-primary-ultra-light border-primary text-primary text-sm rounded-lg focus:ring-primary-dark focus:border-primary-dark block cursor-pointer p-0.5 ${
          error ? "border-red-500" : ""
        }`}
        onChange={onChange}
        value={value}
      />

      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
};

export default ColorInput;
