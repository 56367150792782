import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import Loading from "../Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ButtonProps {
  children: React.ReactNode;
  variant?: "primary" | "primary-light" | "primary-dark" | "primary-green" | "danger" | "link";
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  icon?: IconDefinition;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = "primary",
  onClick,
  disabled = false,
  className = "",
  type = "button",
  icon,
  isLoading,
}) => {
  const variantClasses = {
    primary: "bg-primary-green text-white hover:bg-primary-green-dark",
    "primary-light":
      "bg-primary-light text-primary-dark hover:bg-primary-dark hover:text-primary-light",
    "primary-dark": "bg-primary-dark text-white hover:bg-primary-dark-accent",
    "primary-green": "bg-primary-green hover:bg-primary-green-dark",
    danger: "bg-red-500 text-white hover:bg-red-600",
    link: "text-primary-dark underline hover:text-primary-accent shadow-none",
  };

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`text-sm px-4 py-2 rounded shadow ${
        variant === "link" ? "" : "px-4 py-2"
      } ${variantClasses[variant]} ${
        disabled || isLoading ? "opacity-50 cursor-not-allowed" : "opacity-100"
      } ${className}`}
    >
      {!icon && !isLoading ? (
        children
      ) : !icon && isLoading ? (
        <div className="flex h-5 gap-1">
          <Loading variant="primary-light" classNames="h-5 w-5" />
        </div>
      ) : icon && isLoading ? (
        <div className="flex items-center h-5 gap-1">
          <Loading variant="primary-light" classNames="h-5 w-5" /> {children}
        </div>
      ) : icon && !isLoading ? (
        <div className="flex items-center h-5 gap-1">
          <div className="flex justify-center w-5">
            <FontAwesomeIcon icon={icon} />
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
