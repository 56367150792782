import React from "react";

import styles from "./Construction.module.css";
import Chip from "../../../ui/Chip";

interface InputWithButtonsProps {
  label: string;
  value: number;
  onChange: (newValue: number) => void;
  min: number;
  max: number;
}

const InputWithButtons: React.FC<InputWithButtonsProps> = ({
  label,
  value,
  onChange,
  min,
  max,
}) => {
  const handleInputChange = (newValue: number) => {
    if (newValue < min) {
      onChange(min);
    } else if (newValue > max) {
      onChange(max);
    } else {
      onChange(newValue);
    }
  };

  const handleDecrement = () => {
    handleInputChange(value - 1);
  };

  const handleIncrement = () => {
    handleInputChange(value + 1);
  };

  return (
    <div className="mb-4 flex items-center justify-between">
      <Chip classNames="block text-lg font-medium">{label}:</Chip>

      <div className="relative flex items-center max-w-[8rem]">
        <button
          type="button"
          id="decrement-button"
          data-input-counter-decrement="quantity-input"
          onClick={handleDecrement}
          className="bg-primary-light hover:bg-primary-grey-light border border-gray-300 rounded-s-lg p-1 h-6 focus:ring-gray-100  focus:ring-2 focus:outline-none"
        >
          <svg
            className="w-3 h-3 text-gray-900"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 2"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h16"
            />
          </svg>
        </button>

        <input
          type="number"
          value={value}
          onChange={(e) => onChange(Number(e.target.value))}
          onClick={(e) => (e.target as HTMLInputElement).select()}
          className={`${styles.inputTypeNumber} bg-primary-light border border-white h-6 text-center text-gray-900 text-sm focus:ring-primary-dark focus:border-primary-dark block w-full`}
          min={min}
          max={max}
        />

        <button
          type="button"
          id="increment-button"
          data-input-counter-increment="quantity-input"
          onClick={handleIncrement}
          className="bg-primary-light hover:bg-primary-grey-light border border-gray-300 rounded-e-lg p-1 h-6 focus:ring-primary  focus:ring-2 focus:outline-none"
        >
          <svg
            className="w-3 h-3 text-gray-900"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 1v16M1 9h16"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default InputWithButtons;
