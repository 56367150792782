import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import NavbarLang from "./NavbarLang";
import algreenLogo from "../../assets/images/algreen-logo-green.png";
import algreenLogoShort from "../../assets/images/algreen-logo-short.png";
import { NAVBAR_HEIGHT } from "../../constants";
import LoginModal from "./LoginModal";
import UserDropdownMenu from "./UserDropdownMenu";

interface NavbarProps {
  additionalFields?: ReactNode;
}

const Navbar: React.FC<NavbarProps> = ({ additionalFields }) => {
  const [isLoginModalShown, setIsLoginModalShown] = useState(false);

  return (
    <>
      <nav
        className={`bg-primary-light p-4 border-b border-primary-green ${process.env.REACT_APP_NODE_ENV === "development" ? "bg-primary-light" : ""}`}
        style={{
          height: `${NAVBAR_HEIGHT}px`,          
        }}
      >
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/">
            <div className="hidden sm:block">
              <img src={algreenLogo} alt="Algreen logo" className="h-10" />
            </div>
            <div className="block sm:hidden">
              <img src={algreenLogoShort} alt="Algreen logo" className="h-10" />
            </div>
          </Link>

          <div className="flex gap-4">
            {additionalFields}

            <NavbarLang />

            <UserDropdownMenu setIsLoginModalShown={setIsLoginModalShown} />
          </div>
        </div>
      </nav>

      <LoginModal
        isOpen={isLoginModalShown}
        onClose={() => setIsLoginModalShown(false)}
      />
    </>
  );
};

export default Navbar;
