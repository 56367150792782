import React from "react";

import PrintHeader from "./PrintHeader";

interface SectionData {
  title: string;
  data: Record<string, string | null>;
}

interface ContentToPrintProps {
  sections: Record<string, SectionData>;
  innerDoorImage: string | null;
  outerDoorImage: string | null;
}

const formatDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

const ContentToPrint = React.forwardRef<HTMLDivElement, ContentToPrintProps>(
  ({ sections, innerDoorImage, outerDoorImage }, ref) => {
    return (
      <>
      <div className="px-10 py-4" ref={ref}>
        <PrintHeader />        
        <div className="text-sm">
          <div className="pl-1 pr-1 text-lg mb-4 bg-gray-200 text-black flex justify-between ">
            <p>Referentni broj: koji je ovaj broj?</p>
            <p>{formatDate()}</p>
          </div>
          {Object.values(sections).map((section, sectionIndex) => (
            <div key={section.title} className="mb-4">
              <h3 className="pl-1 pr-1 font-semibold mb-2 bg-gray-300 text-black">
                {section.title}
              </h3>

              <div>
                {Object.entries(section.data).map(([key, value], index) => {
                  if (value === null || value === "") {
                    return null;
                  }                  

                  return (
                    <div
                      key={key}
                      className={`flex items-center justify-between pl-1 pr-1 `}
                    >
                      <span className="font-semibold">{key}:</span>
                      <span>{value}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}          
          
          <div className="page-break"></div>

          
        </div>   
                
        <div className="mt-4">
          <PrintHeader />
        </div>                  

          <div className="flex flex-wrap justify-center gap-10 items-center w-full mt-20">
            <img
              src={`data:image/png;base64,${innerDoorImage}`}
              alt="Inner Door"
              className="max-h-[300px] w-auto"              
            />
            <img
              src={`data:image/png;base64,${outerDoorImage}`}
              alt="Outer Door"
              className="max-h-[300px] w-auto"              
            />
          </div>   

          <h1 className="mt-[150px]">Tabela</h1>

      </div>
      </>
    );
  }
);

export default ContentToPrint;
