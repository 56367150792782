import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import { EquipmentGlass, EquipmentGlassTranslation, Door } from "../types";
import { toBase64, useSWRFetcher, checkDoorFeatures } from "../utils";
import { RootState } from "../app/store";
import {
  ConfigurationState,
  setConfigurationField,
} from "../features/configuration/configurationSlice";

type ProccessedGlass = {
  name: string;
  img: string;
  id: string;
};

const useEquipmentGlasses = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isMutating, setIsMutating] = useState(false);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = i18n.language;

  const {
    data: glasses,
    error,
    mutate,
    isLoading,
  } = useSWRFetcher<EquipmentGlass[]>(
    `${API_URL}/api/equipment-glasses`,
    undefined,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const ornamentGlasses: ProccessedGlass[] = [];
  let decorativeSideGlasses: ProccessedGlass[] = [];

  glasses?.forEach((glass) => {
    const translation = glass.translations.find(
      (t: EquipmentGlassTranslation) => t.language === currentLanguage
    );

    const processedGlass: ProccessedGlass = {
      name: translation ? translation.name : "",
      img: `${API_URL}/storage/${glass.thumbnail}`,
      id: glass.id.toString(),
    };

    if (glass.type === "ornament") {
      ornamentGlasses.push(processedGlass);
    } else if (glass.type === "sideglass") {
      decorativeSideGlasses.push(processedGlass);
    }
  });

  const deleteGlass = async (id: number): Promise<void> => {
    setIsMutating(true);
    try {
      await fetch(`${API_URL}/api/equipment-glasses/${id}`, {
        method: "DELETE",
      });
      mutate();
    } catch (error) {
      throw error;
    } finally {
      setIsMutating(false);
    }
  };

  const addOrUpdateGlass = async (
    id: number | null,
    glassData: Partial<EquipmentGlass>
  ): Promise<void> => {
    setIsMutating(true);
    const dataToUpdate = { ...glassData };

    if (dataToUpdate.thumbnail instanceof File) {
      dataToUpdate.thumbnail = await toBase64(dataToUpdate.thumbnail);
    }

    if (dataToUpdate.sort_order === 0) {
      dataToUpdate.sort_order = null;
    }

    const method = id ? "PUT" : "POST";
    const url = id
      ? `${API_URL}/api/equipment-glasses/${id}`
      : `${API_URL}/api/equipment-glasses`;

    try {
      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToUpdate),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "An error occurred");
      }

      mutate();
    } catch (error) {
      throw error;
    } finally {
      setIsMutating(false);
    }
  };

  const dispatchSelectedGlass = (
    field: keyof ConfigurationState,
    value: string
  ) => dispatch(setConfigurationField({ field, value }));

  const selectedDoorId = useSelector(
    (state: RootState) => state.configuration["model-id"]
  );
  const selectedDoorModel = useSelector(
    (state: RootState) => state.configuration["model"]
  );
  const selectedDoorType = useSelector(
    (state: RootState) => state.configuration["type"]
  );
  const selectedInnerGlass = useSelector(
    (state: RootState) => state.configuration["inner-glass"]
  );
  const selectedSideGlass = useSelector(
    (state: RootState) => state.configuration["side-glass"]
  );
  const selectedTransomGlass = useSelector(
    (state: RootState) => state.configuration["transom-glass"]
  );

  const { hasSideGlass, hasTransom } = checkDoorFeatures(selectedDoorType);

  const fetcher = (url: string) => fetch(url).then((res) => res.json());
  const { data: doorData } = useSWR<Door>(
    selectedDoorId ? `${API_URL}/api/doors/${selectedDoorId}` : null,
    fetcher
  );

  const hasInDoorGlass = doorData?.has_glass;

  const decorativeInnerGlassForModal = doorData?.decorative_glass_name
    ? `${API_URL}/storage/thumbnails/glass/glass${selectedDoorModel}.png`
    : null;

  const decorativeSideGlassForModal = doorData?.side_glass_code
    ? {
        name: doorData.side_glass_code,
        img: `${API_URL}/storage/thumbnails/sideglass/${selectedDoorModel}.jpg`,
        id: doorData.side_glass_code,
      }
    : null;

  if (decorativeSideGlassForModal) {
    decorativeSideGlasses = [
      decorativeSideGlassForModal,
      ...decorativeSideGlasses,
    ];
  }

  const selectedGlasses = {
    inner:
      selectedInnerGlass === "default"
        ? decorativeInnerGlassForModal
          ? "default"
          : "sandblast"
        : selectedInnerGlass,
    side:
      selectedSideGlass === "default"
        ? decorativeSideGlassForModal
          ? "default"
          : "sandblast"
        : selectedSideGlass,
    transom: selectedTransomGlass,
  };

  return {
    glasses,
    error,
    isLoading,
    isMutating,
    deleteGlass,
    addOrUpdateGlass,
    mutate,
    ornamentGlasses,
    decorativeSideGlasses,
    hasTransom,
    hasSideGlass,
    decorativeInnerGlassForModal,
    selectedDoorModel,
    hasInDoorGlass,
    selectedGlasses,
    dispatchSelectedGlass,
  };
};

export default useEquipmentGlasses;
