import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Modal from "../../../ui/Modal";
import Button from "../../../ui/Button";
import usePersonalInfoForm from "../../../../hooks/usePersonalInfoForm";
import PasswordChangeModal from "../PasswordChangeModal";
import { fetchUserData } from "../../../../features/user/userSlice";
import { AppDispatch } from "../../../../app/store";
import UserFormFields from "../../../Admin/Users/UserFormFields";

interface PersonalInfoProps {
  setIsPersonalInfoModalVisible: (boolean: boolean) => void;
  isModalOpen: boolean;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({
  setIsPersonalInfoModalVisible,
  isModalOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { formData, handleInputChange, handleSubmit, setFormData } =
    usePersonalInfoForm();

  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedFormData = { ...formData };

    const success = await handleSubmit(updatedFormData);
    if (success) {
      toast.success(t("auth-messages.update-successfully"));
      dispatch(fetchUserData());
    }
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsPersonalInfoModalVisible(false)}
        title={t("personalInfo")}
      >
        <form onSubmit={handleFormSubmit}>
          <UserFormFields
            formData={formData}
            handleInputChange={handleInputChange}
            setFormData={setFormData}
          />
          <div className="flex justify-between mt-4">
            <Button
              variant="link"
              type="button"
              className="text-primary-light hover:text-white pl-0"
              onClick={() => {
                setPasswordModalOpen(true);
                setIsPersonalInfoModalVisible(false);
              }}
            >
              {t("changePassword")}
            </Button>

            <Button type="submit">{t("update")}</Button>
          </div>
        </form>
      </Modal>

      <PasswordChangeModal
        isModalOpen={isPasswordModalOpen}
        onModalClose={() => setPasswordModalOpen(false)}
        showPersonalInfoModal={() => setIsPersonalInfoModalVisible(true)}
      />
    </>
  );
};

export default PersonalInfo;
