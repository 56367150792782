import React from "react";
import { useTranslation } from "react-i18next";

interface ErrorProps {
  message?: string;
}

const Error: React.FC<ErrorProps> = ({ message }) => {
  const { t } = useTranslation();
  const errorMessage = message || t("error-occurred");

  return (
    <div className="inline-block mx-auto p-4 rounded-lg shadow-md">
      <p className="text-xl font-semibold text-danger mb-2">
        {errorMessage}
      </p>
    </div>
  );
};

export default Error;
