import React from "react";

import styles from "./EquipmentGroup.module.css";
import { Equipment } from "../../../../../types";

interface EquipmentGroupProps {
  groupName: string;
  items: Equipment[];
  selectedItem: number | null;
  onRadioChange: (itemId: number, itemName: string, itemImgName:string) => void;
  groupDescription: string;
  type: string;
}

const EquipmentGroup: React.FC<EquipmentGroupProps> = ({
  groupName,
  items,
  selectedItem,
  onRadioChange,
  groupDescription,
  type,  
}) => {
  return (
    <div className="flex w-full gap-3">
      <div className="w-2/5 flex items-center">
        <img
          src={`${process.env.REACT_APP_API_URL}/storage/thumbnails/equipment/${type}/${groupName}.png`}
          alt={groupName}
        />
      </div>

      <div className="w-3/5 flex flex-col justify-between text-black">
        <div>
          {items.map((item) => (
            <div key={item.id} className="mb-2">
              <input
                id={`radio-${item.id}`}
                className={styles.radioInput}
                type="radio"
                name="equipment"
                value={item.description || item.name}
                checked={selectedItem === item.id}
                onClick={() => onRadioChange(item.id, item.description || item.name, item.equipment_code)}
                readOnly
              />

              <label htmlFor={`radio-${item.id}`} className={styles.radioLabel}>
                <div className="w-5">
                  <span
                    className={`${styles.radioCustom} ${
                      selectedItem === item.id
                        ? "bg-primary-light border-primary-dark"
                        : ""
                    }`}
                  ></span>
                </div>

                {item.description || item.name}
              </label>
            </div>
          ))}
        </div>

        <p className="text-xs">{groupDescription}</p>
      </div>
    </div>
  );
};

export default EquipmentGroup;
