export const textInputFields = [
  { name: "email", type: "email", placeholderKey: "yourEmail", required: true },
  {
    name: "fullName",
    type: "text",
    placeholderKey: "fullName",
    required: false,
  },
  {
    name: "street",
    type: "text",
    placeholderKey: "streetAndNumber",
    required: false,
  },
  {
    name: "postalCode",
    type: "text",
    placeholderKey: "postalCode",
    required: true,
  },
  { name: "city", type: "text", placeholderKey: "city", required: true },
  {
    name: "phone",
    type: "tel",
    placeholderKey: "contactPhone",
    required: true,
  },
];
