export const houseNumberItems = [
  {
    id: 1,
    src: "house-numbers/broj0.jpg",
    alt: "es7010",
    label: "",
  },
  {
    id: 2,
    src: "house-numbers/broj1.jpg",
    alt: "es7020",
    label: "",
  },
  {
    id: 3,
    src: "house-numbers/broj2.jpg",
    alt: "es7030",
    label: "",
  },
  {
    id: 4,
    src: "house-numbers/broj3.jpg",
    alt: "es7040",
    label: "",
  },
];
