import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";

import useOutsideClick from "../../../hooks/useOutsideClick";
import { MAX_WIDTH_SIZES } from "../../../constants";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl"
    | "full";
  closeOnOutsideClick?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  size = "md",
  closeOnOutsideClick = true,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(modalRef, closeOnOutsideClick ? onClose : undefined);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const modalHeader = (
    <div className="flex items-center justify-between mb-4">
      {title ? (
        <h3 className="text-lg font-semibold text-white">{title}</h3>
      ) : (
        <div />
      )}
      <button className="text-white" onClick={onClose} aria-label="Close">
        <FontAwesomeIcon icon={faX} size="lg" />
      </button>
    </div>
  );

  const modalContent = isOpen ? (
    <div className="fixed inset-0 bg-primary bg-opacity-30 backdrop-filter backdrop-blur-sm flex justify-center items-center p-4 z-50">
      <div
        ref={modalRef}
        className={`bg-primary-grey rounded-lg p-6 w-full ${MAX_WIDTH_SIZES[size]} flex flex-col max-h-screen`}
      >
        {modalHeader}
        <div className="overflow-y-auto flex-grow no-scrollbar">{children}</div>
      </div>
    </div>
  ) : null;

  return ReactDOM.createPortal(modalContent, document.body);
};

export default Modal;
