import { EquipmentItem } from "../../../../../types";

export const automaticClosingDeviceItems: EquipmentItem[] = [
  {
    id: 1,
    src: "door_closer.png",
    alt: "24-76-00207",
    label: "24-76-00207\nOverhead Door Closer",
  },
];
