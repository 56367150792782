import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRightToBracket } from "@fortawesome/free-solid-svg-icons";

import { AppDispatch } from "../../../app/store";
import {
  logoutUser,
  selectIsLoggedIn,
  selectUserData,
} from "../../../features/user/userSlice";
import Dropdown, { DropdownItem } from "../../ui/Dropdown";
import PersonalInfo from "./PersonalInfo";

interface UserDropdownMenuProps {
  setIsLoginModalShown: (boolean: boolean) => void;
}

const UserDropdownMenu: React.FC<UserDropdownMenuProps> = ({
  setIsLoginModalShown,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isAdmin = useSelector(selectUserData)?.role === "admin";

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [isPersonalInfoModalVisible, setIsPersonalInfoModalVisible] =
    useState(false);

  const handleLogout = async () => {
    const resultAction = await dispatch(logoutUser());
    if (logoutUser.fulfilled.match(resultAction)) {
      toast.success(t("auth-messages.logout-successfully"));
    } else {
      toast.error(t("auth-messages.logout-unsuccessfully"));
    }
  };

  const dropdownItems: DropdownItem[] = [
    {
      key: "personalInfo",
      label: t("personalInfo"),
      action: () => setIsPersonalInfoModalVisible(true),
    },
    { key: "notifications", label: t("notifications") },
  ];

  if (isAdmin) {
    dropdownItems.push({
      key: "adminPanel",
      label: t("adminPage"),
      action: () => navigate("/admin"),
    });
  }

  dropdownItems.push({
    key: "logout",
    label: t("logout"),
    action: handleLogout,
    isAdditional: true,
  });

  return isLoggedIn ? (
    <>
      <Dropdown
        trigger={
          <FontAwesomeIcon
            icon={faGear}
            size="2x"
            className="cursor-pointer text-black hover:text-primary-green"
          />
        }
        items={dropdownItems}
        className="right-0 w-48 bg-translucent text-white shadow-lg rounded-xl"
      />

      <PersonalInfo
        isModalOpen={isPersonalInfoModalVisible}
        setIsPersonalInfoModalVisible={setIsPersonalInfoModalVisible}
      />
    </>
  ) : (
    <span
      onClick={() => setIsLoginModalShown(true)}
      className="cursor-pointer text-black hover:text-primary-green"
    >
      <FontAwesomeIcon icon={faRightToBracket} size="2x" />
    </span>
  );
};

export default UserDropdownMenu;
