import React, { useState, useEffect } from "react";
import { ColorCategory } from "../../../types";
import Input from "../../ui/Input";
import Checkbox from "../../ui/Checkbox";
import FormFooter from "../AdminComponents/FormFooter";

interface AdminCategoryFormProps {
  category: ColorCategory | null;
  onSubmit: (categoryData: Partial<ColorCategory>) => void;
  onCancel: () => void;
  isMutating: boolean;
}

const AdminCategoryForm: React.FC<AdminCategoryFormProps> = ({
  category,
  onSubmit,
  onCancel,
  isMutating,
}) => {
  const [categoryName, setCategoryName] = useState(
    category?.color_category_name || ""
  );
  const [showInStock, setShowInStock] = useState(
    category?.show_in_stock ?? true
  );
  const [showInPanel, setShowInPanel] = useState(
    category?.show_in_panel ?? true
  );

  useEffect(() => {
    if (category) {
      setCategoryName(category.color_category_name);
      setShowInStock(category.show_in_stock ?? true);
      setShowInPanel(category.show_in_panel ?? true);
    }
  }, [category]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const categoryData: Partial<ColorCategory> = {
      color_category_name: categoryName,
      show_in_stock: showInStock,
      show_in_panel: showInPanel,
    };
    onSubmit(categoryData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <Input
          label="Ime kategorije"
          name="categoryName"
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <Checkbox
          checked={showInStock}
          onChange={setShowInStock}
          label="Prikaži u štoku"
        />
      </div>

      <div className="mb-4">
        <Checkbox
          checked={showInPanel}
          onChange={setShowInPanel}
          label="Prikaži u panelu"
        />
      </div>

      <FormFooter onCancel={onCancel} isMutating={isMutating} />
    </form>
  );
};

export default AdminCategoryForm;
