export const handrailGroupsData = [
    { groupName: "es-40", description: "Diameter: ø30mm", key: "es40" },
    { groupName: "es-40-bl", description: "Diameter: ø30mm", key: "es40b" },
    { groupName: "es-60", description: "Section: 40x10mm", key: "es60ss" },
    { groupName: "es-60-bl", description: "Section: 40x10mm", key: "es60b" },
    { groupName: "es-90", description: "Section: 40x10mm", key: "es90ss" },
    { groupName: "es-90-bl", description: "Section: 40x10mm", key: "es90b" },
    { groupName: "es-80", description: "Section: 40x10mm", key: "es80ss" },
    { groupName: "es-80-bl", description: "Section: 40x10mm", key: "es80b" },
    { groupName: "es-130", description: "Section: 30x20mm", key: "es130ss" },
    { groupName: "es-130-bl", description: "Section: 30x20mm", key: "es130b" },
    { groupName: "es-105", description: "Section: 40x20mm", key: "es105ss" },
    { groupName: "es-105-sse", description: "Section: 40x20mm", key: "es105sse" },
    { groupName: "es-105-bl", description: "Section: 40x20mm", key: "es105b" },
    { groupName: "es-105-ble", description: "Section: 40x20mm", key: "es105be" },
    { groupName: "es-104", description: "Section: 30x20mm", key: "es104ss" },
    { groupName: "es-104-bl", description: "Section: 30x20mm", key: "es104b" },
    { groupName: "es-200", description: "Diameter: ø40mm", key: "es200" },
    {
      groupName: "es-320",
      description: "Stainless steel handrail",
      key: "es320",
    },
    {
      groupName: "es-270",
      description: "Stainless steel handrail",
      key: "es270",
    },
    {
      groupName: "es-240",
      description: "Stainless steel handrail",
      key: "es240",
    },
    {
      groupName: "es-280",
      description: "Stainless steel handrail",
      key: "es280",
    },
    {
      groupName: "es-250",
      description: "Stainless steel handrail",
      key: "es250",
    },
  ];