import React, { useState } from "react";
import Chip from "../../ui/Chip";
import Button from "../../ui/Button";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../ui/Loading";
import { useDoors } from "../../../hooks/useDoors";
import Error from "../../ui/Error";
import TabSwitcher from "../../ui/TabSwitcher";
import Table, { TableColumn } from "../../ui/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Door } from "../../../types";
import Modal from "../../ui/Modal";
import AdminDeleteConfirmationModal from "../AdminComponents/AdminTableItems/AdminDeleteConfirmationModal";
import AdminDoorForm from "./AdminDoorForm";
import ImagePreview from "../AdminComponents/AdminTableItems/ImagePreview";
import StatusIndicator from "../AdminComponents/AdminTableItems/StatusIndicator";

const AdminDoorTypes: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    "all" | "without-glass" | "with-glass"
  >("all");
  const [editingDoor, setEditingDoor] = useState<Door | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [doorToDelete, setDoorToDelete] = useState<Door | null>(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const { doors, isLoading, isError, deleteDoor, addOrUpdateDoor } =
    useDoors(activeTab);

  const columns: TableColumn[] = [
    { header: "ID", accessor: "id", sortable: true, filterable: false },
    {
      header: "Model Code",
      accessor: "model_code",
      sortable: true,
      filterable: true,
    },
    {
      header: "Cena",
      accessor: "price",
      render: (row: Door) =>
        row.price ? parseFloat(row.price).toFixed(2) : "-",
      sortable: true,
      filterable: true,
    },
    {
      header: "Redosled",
      accessor: "sort_order",
      render: (row: Door) => (row.sort_order ? `#${row.sort_order}` : "-"),
      sortable: true,
    },
    {
      header: "Slika",
      accessor: "thumbnail",
      render: (row: Door) => (
        <ImagePreview
          isNonRectangular
          thumbnail={row.thumbnail as string}
          name={row.model_code}
        />
      ),
    },
    {
      header: "Status",
      accessor: "is_shown",
      render: (row: Door) => <StatusIndicator isShown={row.is_shown} />,
      sortable: true,
    },
  ];

  const handleAddOrUpdateDoor = async (doorData: Partial<Door>) => {
    try {
      await addOrUpdateDoor(editingDoor ? editingDoor.id : null, doorData);
      toast.success(
        editingDoor ? "Vrata su uspešno ažurirana" : "Vrata su uspešno dodata"
      );
    } catch (error) {
      toast.error("Greška prilikom čuvanja vrata");
    } finally {
      setIsFormModalOpen(false);
      setEditingDoor(null);
    }
  };

  const handleCancel = () => {
    setIsFormModalOpen(false);
    setEditingDoor(null);
  };

  const handleDeleteClick = (door: Door) => {
    setDoorToDelete(door);
    setIsConfirmModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (doorToDelete) {
      try {
        await deleteDoor(doorToDelete.id);
        toast.success("Vrata je uspešno obrisana");
      } catch (error) {
        toast.error("Greška prilikom brisanja vrata");
      } finally {
        setDoorToDelete(null);
        setIsConfirmModalOpen(false);
      }
    }
  };

  const handleEditClick = (door: Door) => {
    setEditingDoor(door);
    setIsFormModalOpen(true);
  };

  const handleAddNewClick = () => {
    setEditingDoor(null);
    setIsFormModalOpen(true);
  };

  return (
    <div className="flex flex-col gap-3 p-4">
      <Chip classNames="w-fit">
        <h3 className="text-2xl font-bold">Modeli vrata</h3>
      </Chip>

      <TabSwitcher
        activeTab={activeTab}
        tabs={[
          { label: "Sva", value: "all" },
          { label: "Bez stakla", value: "without-glass" },
          { label: "Sa staklom", value: "with-glass" },
        ]}
        onTabChange={setActiveTab}
      />

      <div className="flex justify-center">
        <Button
          icon={faPlus}
          onClick={handleAddNewClick}
          className="w-fit"
          disabled={isLoading}
        >
          Dodaj nova vrata
        </Button>
      </div>

      {isLoading && <Loading />}

      {isError && <Error message="Došlo je do greške" />}

      {doors && doors.length > 0 && (
        <Table
          striped
          pagination
          columns={columns}
          data={doors}
          actionButtons={(row: Door) => (
            <div className="flex space-x-2">
              <Button onClick={() => handleEditClick(row)}>
                <FontAwesomeIcon icon={faPen} />
              </Button>

              <Button variant="danger" onClick={() => handleDeleteClick(row)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          )}
        />
      )}
      {isFormModalOpen && (
        <Modal
          isOpen={isFormModalOpen}
          onClose={handleCancel}
          title={editingDoor ? "Ažuriraj boju" : "Dodaj novu boju"}
        >
          <AdminDoorForm
            door={editingDoor}
            onSubmit={handleAddOrUpdateDoor}
            onCancel={handleCancel}
            isMutating={isLoading}
          />
        </Modal>
      )}

      <AdminDeleteConfirmationModal
        isMutating={isLoading}
        setIsConfirmModalOpen={setIsConfirmModalOpen}
        handleConfirmDelete={handleConfirmDelete}
        isConfirmModalOpen={isConfirmModalOpen}
        text="Da li ste sigurni da želite da obrišete ovu boju?"
      />
    </div>
  );
};

export default AdminDoorTypes;
