import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../../app/store";
import useUserForm from "../../../../hooks/useUserForm";
import { registerUser } from "../../../../features/user/userSlice";
import Chip from "../../../ui/Chip";
import UserFormFields from "../UserFormFields";
import Modal from "../../../ui/Modal";
import { useUsers } from "../../../../hooks/useUsers";
import FormFooter from "../../AdminComponents/FormFooter";

interface RegisterUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const RegisterUserModal = ({ isOpen, onClose }: RegisterUserModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { formData, handleChange, validate, resetForm, setFormData } =
    useUserForm();
  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = useUsers(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate()) {
      setIsLoading(true);
      try {
        const resultAction = await dispatch(registerUser(formData));
        unwrapResult(resultAction);
        toast.success(t("auth-messages.register-successfully"));
        await refetch();
        resetForm();
        onClose();
      } catch (error: any) {
        console.error("Component error:", error);

        if (error.email) {
          toast.error(t(error.email[0]));
        } else if (typeof error === "string") {
          toast.error(t(error));
        } else if (error instanceof Error) {
          toast.error(t(error.message));
        } else {
          toast.error(t("auth-messages.an-unknown-error-occurred"));
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <div className="flex flex-col align-center gap-6 rounded-md">
        <Chip>
          <h1 className="text-2xl font-bold">{t("register-new-user")}</h1>
        </Chip>

        <form onSubmit={handleSubmit}>
          <UserFormFields
            formData={formData}
            handleInputChange={handleChange}
            setFormData={setFormData}
            mode="create"
          />

          <FormFooter onCancel={onClose} isMutating={isLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default RegisterUserModal;
