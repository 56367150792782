import React from "react";
import { useSelector } from "react-redux";
import { selectActiveItem } from "../../features/sidebar/sidebarSlice";
import useDoorImage from "../../hooks/useDoorImage";
import Error from "../ui/Error";
import Loading from "../ui/Loading";
import styles from "./Main.module.css";
import { NAVBAR_HEIGHT } from "../../constants";
import { useTranslation } from "react-i18next";
import RndDoorImage from "./RndDoorImage";

interface MainProps {
  isSidebarOpen: boolean;
  hideSidebar: React.MouseEventHandler<HTMLElement>;
}

const Main = ({ isSidebarOpen, hideSidebar }: MainProps) => {
  const { t } = useTranslation();
  const activeItem = useSelector(selectActiveItem);
  const { displayedImage, isError, isLoading } = useDoorImage();

  let content;

  if (isError) {
    content = <Error message={t("error-occurred")} />;
  } else if (isLoading || !displayedImage) {
    content = <Loading />;
  } else {
    content =
      activeItem === "insert-the-door" ? (
        <RndDoorImage imageUrl={displayedImage} />
      ) : (
        <img
          className={styles.door}
          src={displayedImage}
          alt="Displayed Door"
        />
      );
  }

  const insertTheDoorStyles: React.CSSProperties = {
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    overflow: "hidden",
  };

  const notInsertTheDoorClasses = `justify-center items-end flex-grow p-4  ${
    isSidebarOpen ? "hidden md:flex" : "flex"
  }`;

  const contentContainerStyle: React.CSSProperties = {
    maxHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    overflow: "hidden",
    paddingTop: "2rem",
  };

  return activeItem === "insert-the-door" ? (
    <div
      style={insertTheDoorStyles}
      className="absolute w-full"
      onMouseDown={hideSidebar}
    >
      {content}
    </div>
  ) : (
    <div
      className={`${notInsertTheDoorClasses} ${styles.doorContainer}`}
      style={contentContainerStyle}
      onClick={hideSidebar}
    >
      {content}
    </div>
  );
};

export default Main;
