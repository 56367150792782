import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Select from "../../../ui/Select";
import { useEquipments } from "../../../../hooks/useEquipments";
import Loading from "../../../ui/Loading";
import Error from "../../../ui/Error";
import Handrails from "./Handrails";
import EquipmentGrid from "./EquipmentGrid";
import { rosetteItems } from "./items/rosetteItems";
import { doorknobItems } from "./items/doorknobItems";
import { parapetProtectionItems } from "./items/parapetProtectionItems";
import EquipmentRows from "./EquipmentRows";
import AccessControl from "./AccessControl";
import { spyItems } from "./items/spyItems";
import { electromagneticReceiverItems } from "./items/electromagneticReceiverItems";
import { automaticClosingDeviceItems } from "./items/automaticClosingDeviceItems";
import Hinges from "./Hinges";
import { houseNumberItems } from "./items/houseNumberItems";
import {
  EquipmentSelections,
  setEquipmentSelection,
} from "../../../../features/configuration/configurationSlice";
import { RootState } from "../../../../app/store";
import { setActiveDropDownItem } from "../../../../features/sidebar/sidebarSlice";
import Chip from "../../../ui/Chip";
import Cylinders from "./Cylinders";

const Equipment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { grouped, equipmentInCategories, isLoading, isError } =
    useEquipments();

  const selectedDropDownItem = useSelector(
    (state: RootState) => state.sidebar.activeDropDownItem.equipment
  );

  const currentSelections = useSelector(
    (state: RootState) => state.configuration.equipment
  );

  const selectOptions = [
    { value: "handrails", label: t("handrails") },
    { value: "doorknob-outside", label: t("doorknob-outside") },
    { value: "doorknob-inside", label: t("doorknob-inside") },
    { value: "cylinder", label: t("cylinder") },
    { value: "rosette", label: t("rosette") },
    { value: "parapet-protection", label: t("parapet-protection") },
    { value: "access-control", label: t("access-control") },
    { value: "spy", label: t("spy") },
    { value: "hinges", label: t("hinges") },
    { value: "electromagnetic-receiver", label: t("electromagnetic-receiver") },
    { value: "automatic-closing-device", label: t("automatic-closing-device") },
    { value: "house-numbers", label: t("house-numbers") },
  ];

  const handleSelectDropDownItem = (value: string) => {
    dispatch(setActiveDropDownItem({ field: "equipment", value }));
  };

  const handleItemSelect = (
    id: number | null,
    name: string | null,
    imageName: string | null,
    category: string
  ) => {
    dispatch(
      setEquipmentSelection({
        category: category as keyof EquipmentSelections,
        selection: { id, name, imageName },
      })
    );
  };

  const renderComponentBasedOnSelection = () => {
    switch (selectedDropDownItem) {
      case "handrails":
        return <Handrails grouped={grouped} />;

      case "doorknob-inside":
        return (
          <EquipmentRows
            items={doorknobItems}
            selectedItem={currentSelections.doorknobInside.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "doorknobInside")
            }
          />
        );

      case "cylinder":
        return (
          <Cylinders
            cylinders={equipmentInCategories["7"]}
            selectedItem={currentSelections.cylinder.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "cylinder")
            }
          />
        );

      case "rosette":
        return (
          <EquipmentGrid
            items={rosetteItems}
            selectedItem={currentSelections.rosette.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "rosette")
            }
          />
        );

      case "parapet-protection":
        return (
          <EquipmentRows
            items={parapetProtectionItems}
            selectedItem={currentSelections.parapetProtection.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, name, imageName, "parapetProtection")
            }
            itemCanBeRemoved
          />
        );

      case "access-control":
        return (
          <AccessControl
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, name, imageName, "accessControl")
            }
            grouped={grouped}
            selectedItem={currentSelections.accessControl.id}
          />
        );

      case "spy":
        return (
          <EquipmentRows
            items={spyItems}
            selectedItem={currentSelections.spy.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "spy")
            }
            itemCanBeRemoved
          />
        );

      case "hinges":
        return (
          <Hinges
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, name, imageName, "hinges")
            }
            grouped={grouped}
            selectedItem={currentSelections.hinges.id}
          />
        );

      case "electromagnetic-receiver":
        return (
          <EquipmentRows
            items={electromagneticReceiverItems}
            selectedItem={currentSelections.electromagneticReceiver.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(
                id,
                imageName,
                imageName,
                "electromagneticReceiver"
              )
            }
            itemCanBeRemoved
          />
        );

      case "automatic-closing-device":
        return (
          <EquipmentRows
            items={automaticClosingDeviceItems}
            selectedItem={currentSelections.automaticClosingDevice.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(
                id,
                imageName,
                imageName,
                "automaticClosingDevice"
              )
            }
            itemCanBeRemoved
          />
        );

      case "house-numbers":
        return (
          <EquipmentGrid
            items={houseNumberItems}
            selectedItem={currentSelections.houseNumbers.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "houseNumbers")
            }
            itemCanBeRemoved
          />
        );

      default:
        return null;
    }
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  return (
    <>
      <Chip classNames="mb-10">
        <h3 className=" text-2xl font-bold text-primary-green text-center">{t("choose-door-equipment")}</h3>
      </Chip>

      <Select
        options={selectOptions}
        value={selectedDropDownItem}
        onChange={(value: string) => handleSelectDropDownItem(value)}
      />

      {renderComponentBasedOnSelection()}
    </>
  );
};

export default Equipment;
