import { EquipmentItem } from "../../../../../types";

export const rosetteItems: EquipmentItem[] = [
  {
    id: 1,
    src: "971-807-6500.png",
    alt: "971-807-6500",
    label: "Rozeta 1",
  },
  {
    id: 2,
    src: "359-794-6500.png",
    alt: "359-794-6500",
    label: "Rozeta 2",
  },
  {
    id: 3,
    src: "R0.png",
    alt: "24-68-00200",
    label: "R0",
  },
  {
    id: 4,
    src: "RP1.png",
    alt: "23-51-00301",
    label: "RP1",
  },
  {
    id: 5,
    src: "RP2.png",
    alt: "23-51-00302",
    label: "RP2",
  },
  {
    id: 6,
    src: "RP3.png",
    alt: "23-51-00303",
    label: "RP3",
  },
  {
    id: 7,
    src: "RP4.png",
    alt: "23-51-00304",
    label: "RP4",
  },
  {
    id: 8,
    src: "RP5.png",
    alt: "23-51-00305",
    label: "RP5",
  },
];
