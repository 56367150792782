import React from "react";
import Selectable from "../../../ui/Selectable";

type GlassGridProps = {
  items: Array<{ name: string; img: string; id: string }>;
  onSelect: (id: string) => void;
  selectedGlasses: {
    side: string;
    transom: string;
    inner: string | null;
  };
  chosenSelect: string;
};

const GlassGrid: React.FC<GlassGridProps> = ({
  items,
  onSelect,
  selectedGlasses,
  chosenSelect,
}) => {
  let selectedValue: string | null = "";

  if (chosenSelect === "doorglass") {
    selectedValue = selectedGlasses.inner;
  }else if (chosenSelect === "sideglasses") {
    selectedValue = selectedGlasses.side;
  }else if (chosenSelect === "skylight") {
    selectedValue = selectedGlasses.transom;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
      {items.map((item) => (
        <Selectable
          key={item.name}
          classNames="text-center cursor-pointer hover:scale-105 transition-transform"
          onClick={() => onSelect(item.id)}
          isSelected={selectedValue === item.id}
        >
          <img src={item.img} alt={item.name} />

          <p>{item.name}</p>
        </Selectable>
      ))}
    </div>
  );
};

export default GlassGrid;
