import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Select from "../Select";

interface TablePaginationProps {
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  onItemsPerPageChange: (value: string) => void;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  currentPage,
  totalPages,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const { t } = useTranslation();
  
  return (
    <div className="flex items-center justify-between p-4">
      <Select
        options={[
          { value: "10", label: "10" },
          { value: "25", label: "25" },
          { value: "50", label: "50" },
          { value: "100", label: "100" },
        ]}
        value={itemsPerPage.toString()}
        onChange={onItemsPerPageChange}
      />

      <div>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-3 py-1 mx-1 ${
            currentPage === 1 ? "bg-primary-light-accent" : "bg-primary-light"
          } rounded-full`}
        >
          <FontAwesomeIcon icon={faCaretLeft} className="text-primary-dark" />
        </button>

        <span className="px-4 py-2 mx-1">
          {t("page")} {currentPage} of {totalPages}
        </span>

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 mx-1 ${
            currentPage === totalPages
              ? "bg-primary-light-accent"
              : "bg-primary-light"
          } rounded-full`}
        >
          <FontAwesomeIcon icon={faCaretRight} className="text-primary-dark" />
        </button>
      </div>
    </div>
  );
};

export default TablePagination;
