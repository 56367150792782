import { useState } from "react";
import { useTranslation } from "react-i18next";

import { RegisterFormData } from "../types";

const useUserForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<RegisterFormData>({
    company_name: "",
    city: "",
    zip_code: "",
    address: "",
    pib: "",
    social_number: "",
    giro_account: "",
    phone: "",
    mobile_phone: "",
    email: "",
    contact_person: "",
    currency: "RSD",
    username: "",
    password: "",
    logo: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};

    if (formData.password !== formData.confirm_password) {
      newErrors.confirm_password = t("auth-messages.passwords-dont-match");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const resetForm = () => {
    setFormData({
      company_name: "",
      city: "",
      zip_code: "",
      address: "",
      pib: "",
      social_number: "",
      giro_account: "",
      phone: "",
      mobile_phone: "",
      email: "",
      contact_person: "",
      currency: "RSD",
      username: "",
      password: "",
      logo: "",
      confirm_password: "",
    });
    setErrors({});
  };

  return {
    formData,
    setFormData,
    errors,
    handleChange,
    validate,
    resetForm,
  };
};

export default useUserForm;
