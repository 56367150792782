import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { RootState } from "../app/store";
import { debounce } from "../utils";

interface ConfigurationState {
  model: string | null;
  type: string;
}

interface UseDoorImageReturn {
  displayedImage: string | null;
  isLoading: boolean;
  isError: boolean;
}

const postFetcher = async (
  url: string,
  data: ConfigurationState
): Promise<string> => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

const useDoorImage = (): UseDoorImageReturn => {
  const selectedConfiguration = useSelector(
    (state: RootState) => state.configuration
  );

  const [doorImage, setDoorImage] = useState<string | null>(null);
  const [displayedImage, setDisplayedImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchAndUpdateDoorImage = async (config: ConfigurationState) => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "default-api-url";
      const imageUrl = await postFetcher(`${apiUrl}/api/door/image`, config);
      setDoorImage(imageUrl);
      setIsError(false);
    } catch (error) {
      console.error("Fetch error:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetch = useMemo(
    () => debounce(fetchAndUpdateDoorImage, 500),
    []
  );

  useEffect(() => {
    if (doorImage && !isLoading) {
      setDisplayedImage(doorImage);
    }
  }, [doorImage, isLoading]);

  useEffect(() => {
    if (selectedConfiguration) {
      debouncedFetch(selectedConfiguration);
    }
  }, [selectedConfiguration, debouncedFetch]);

  return {
    displayedImage,
    isLoading,
    isError,
  };
};

export default useDoorImage;
