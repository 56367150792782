import { EquipmentItem } from "../../../../../types";

export const doorknobItems: EquipmentItem[] = [
  {
    id: 1,
    src: "995-525-6500.png",
    alt: "995-525-6500",
    label: "995-525-6500\nKvaka 1",
  },
  {
    id: 2,
    src: "396-944-6510.png",
    alt: "396-944-6510",
    label: "396-944-6510\nKvaka 2",
  },
  {
    id: 3,
    src: "396-956-6510.png",
    alt: "396-956-6510",
    label: "396-956-6510\nKvaka 3",
  },
  {
    id: 4,
    src: "396-947-6510.png",
    alt: "396-947-6510",
    label: "396-947-6510\nKvaka 4",
  },
  {
    id: 5,
    src: "217-271-3388.png",
    alt: "217-271-3388",
    label: "217-271-3388\nKvaka 5",
  },
  {
    id: 6,
    src: "217-271-3414.png",
    alt: "217-271-3414",
    label: "217-271-3414\nKvaka 6",
  },
  {
    id: 7,
    src: "standard_silver.png",
    alt: "24-76-00047",
    label: "24-76-00047 / 24-76-00046\nStandard Silver",
  },
  {
    id: 8,
    src: "standard_gold.png",
    alt: "24-76-00049",
    label: "24-76-00049 / 24-76-00039\nStandard Gold",
  },
  {
    id: 9,
    src: "oval_silver.png",
    alt: "24-51-00152",
    label: "24-51-00152 / 24-76-00046\nOval Silver",
  },
  {
    id: 10,
    src: "curved_silver.png",
    alt: "24-51-00153",
    label: "24-51-00153 / 24-76-00046\nCurved Silver",
  },
  {
    id: 11,
    src: "plate_black.png",
    alt: "24-68-00214",
    label: "24-68-00214\nPlate Black",
  },
  {
    id: 12,
    src: "plate_silver.png",
    alt: "24-68-00211",
    label: "24-68-00211\nPlate Silver",
  },
  {
    id: 13,
    src: "plate_white.png",
    alt: "24-68-00210",
    label: "24-68-00210\nPlate White",
  },
];
