import React, { ReactNode } from "react";
import AdminSystems from "./AdminSystems";
import AdminColors from "./AdminColors";
import AdminGlasses from "./AdminGlasses";
import AdminDoorTypes from "./AdminDoorTypes";

const subNavData: { name: string; component: ReactNode; disabled: boolean }[] =
  [
    { name: "Sistemi", component: <AdminSystems />, disabled: false },
    { name: "Boje", component: <AdminColors />, disabled: false },
    { name: "Stakla", component: <AdminGlasses />, disabled: false },
    { name: "Modeli vrata", component: <AdminDoorTypes />, disabled: false },
    { name: "Brave", component: <AdminSystems />, disabled: true },
    { name: "Boje kuća", component: <AdminSystems />, disabled: true },
    { name: "Kuće", component: <AdminSystems />, disabled: true },
  ];

export default subNavData;
