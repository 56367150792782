import React from "react";

import { activeSidebarItem } from "../../../types";
import DoorTypes from "./DoorTypes";
import Systems from "./Systems";
import DoorModel from "./DoorModel";
import Construction from "./Construction";
import Color from "./Color";
import Glass from "./Glass";
import Equipment from "./Equipment";
import Lock from "./Lock";
import HouseColors from "./HouseColors";
import Houses from "./Houses";
import PrintQuery from "./PrintQuery";

const SidebarContent = ({ activeItem, setIsSidebarOpen }: { activeItem: activeSidebarItem; setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>; }) => {
  if (activeItem === "type") {
    return <DoorTypes />;
  } else if (activeItem === "systems") {
    return <Systems />;
  } else if (activeItem === "door-model") {
    return <DoorModel />;
  } else if (activeItem === "construction") {
    return <Construction />;
  } else if (activeItem === "color") {
    return <Color />;
  } else if (activeItem === "glass") {
    return <Glass />;
  } else if (activeItem === "equipment") {
    return <Equipment />;
  } else if (activeItem === "lock") {
    return <Lock />;
  } else if (activeItem === "house-colors") {
    return <HouseColors />;
  } else if (activeItem === "insert-the-door") {
    return <Houses setIsSidebarOpen={setIsSidebarOpen} />;
  } else if (activeItem === "print-query") {
    return <PrintQuery />;
  } else {
    return <></>;
  }
};

export default SidebarContent;
