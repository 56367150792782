import React from "react";
import Chip from "../Chip";
import { useTranslation } from "react-i18next";

interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps {
  options: SelectOption[];
  value: string;
  onChange: (value: string) => void;
  additionalClasses?: string;
  classNames?: string;
  label?: string;
  includeNoneOption?: boolean;
}

const Select: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  additionalClasses = "",
  classNames,
  label,
  includeNoneOption = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`${additionalClasses}`}>
      {label && <Chip classNames="mb-2 w-fit">{label}</Chip>}
      <select
        className={`text-black border border-gray-300 text-sm rounded-lg focus:ring-primary-dark focus:border-primary-dark block w-full p-2.5 outline-none ${classNames}`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {includeNoneOption && <option value="">--- {t("choose")} ---</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
