import useSWR from "swr";
import { Door } from "../types";
import { useEffect, useState } from "react";

interface UseDoorsReturn {
  doors: Door[] | undefined;
  isLoading: boolean;
  isError: boolean;
  revalidate: () => Promise<boolean>;
  deleteDoor: (id: number) => Promise<void>;
  addOrUpdateDoor: (
    id: number | null,
    doorData: Partial<Door>
  ) => Promise<void>;
}

interface ApiResponse {
  success: boolean;
  doors: Door[];
}

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const useDoors = (filter: string): UseDoorsReturn => {
  const [filteredDoors, setFilteredDoors] = useState<Door[]>([]);

  const { data, error, mutate } = useSWR<ApiResponse>(
    `${process.env.REACT_APP_API_URL}/api/doors`,
    fetcher
  );

  const doors = data?.doors;

  useEffect(() => {
    if (doors) {
      const filtered = doors.filter((door) => {
        switch (filter) {
          case "with-glass":
            return door.has_glass;
          case "without-glass":
            return !door.has_glass;
          case "all":
          default:
            return true;
        }
      });
      setFilteredDoors(filtered);
    }
  }, [doors, filter]);

  const revalidate = async (): Promise<boolean> => {
    await mutate();
    return !error;
  };

  const deleteDoor = async (id: number): Promise<void> => {
    await fetch(`${process.env.REACT_APP_API_URL}/api/doors/${id}`, {
      method: "DELETE",
    });
    await mutate();
  };

  const addOrUpdateDoor = async (
    id: number | null,
    doorData: Partial<Door>
  ): Promise<void> => {
    const url = id
      ? `${process.env.REACT_APP_API_URL}/api/doors/${id}`
      : `${process.env.REACT_APP_API_URL}/api/doors`;

    const method = id ? "PUT" : "POST";

    await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(doorData),
    });

    await mutate();
  };

  return {
    doors: filteredDoors,
    isLoading: !error && !data,
    isError: !!error,
    revalidate,
    deleteDoor,
    addOrUpdateDoor,
  };
};
