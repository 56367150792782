import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import App from "../App";
import AdminPanel from "../pages/AdminPanel";
import AdminRoute from "./AdminRoute";
import UsersPage from "../pages/UsersPage";
import NotFound from "../pages/NotFound";

const RouterConfig = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      
      <Route
        path="/admin"
        element={
          <AdminRoute>
            <AdminPanel />
          </AdminRoute>
        }
      />

      <Route
        path="/admin/users"
        element={
          <AdminRoute>
            <UsersPage />
          </AdminRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);

export default RouterConfig;
