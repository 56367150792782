import React, { ChangeEventHandler } from "react";
import Chip from "../Chip";

interface TextAreaProps {
  label?: string;
  name: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  cols?: number;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  name,
  value,
  onChange,
  rows = 4,
  cols,
  ...rest
}) => {
  return (
    <div>
      {label && (
        <label htmlFor={name} className="block mb-2 text-sm font-medium w-fit">
          <Chip>
            {label} {rest.required && <span className="text-red-500">*</span>}
          </Chip>
        </label>
      )}

      <textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
        cols={cols}
        className="bg-primary-ultra-light border-primary text-primary text-sm rounded-lg focus:ring-primary-dark focus:border-primary-dark block w-full p-2.5"
        placeholder={rest.placeholder ?? ""}
        required={rest.required ?? false}
        {...rest}
      ></textarea>
    </div>
  );
};

export default TextArea;
