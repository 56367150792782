import React, { ReactNode, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";

export interface DropdownItem {
  key: string;
  label: ReactNode;
  action?: () => void;
  isAdditional?: boolean;
}

interface DropdownProps {
  trigger: React.ReactNode;
  items: DropdownItem[];
  className?: string;
  mainClass?: string;
  additionalClass?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  trigger,
  items,
  className,
  mainClass = "text-left px-4 py-2 border border-primary bg-primary-light rounded-xl text-sm font-medium cursor-pointer hover:bg-primary-green text-black hover:text-primary-light",
  additionalClass = "text-left px-4 py-2 border border-primary bg-primary-light rounded-xl text-sm font-medium cursor-pointer hover:bg-primary text-primary hover:text-primary-light",
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useOutsideClick(dropdownRef, closeDropdown);

  const mainItems = items.filter((item) => !item.isAdditional);
  const additionalItems = items.filter((item) => item.isAdditional);

  return (
    <div className="relative" ref={dropdownRef}>
      <span onClick={toggleDropdown}>{trigger}</span>
      {dropdownOpen && (
        <div className={`absolute z-50 mt-2 ${className || ""}`}>
          {mainItems.map((item) => (
            <div key={item.key} onClick={item.action} className={mainClass}>
              {item.label}
            </div>
          ))}

          {additionalItems.length > 0 && (
            <div className="flex justify-center">
              <hr className="my-1 w-11/12 border-primary-green" />
            </div>
          )}

          {additionalItems.map((item) => (
            <div
              key={item.key}
              onClick={item.action}
              className={additionalClass}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
