import React from "react";
import { useTranslation } from "react-i18next";

interface SidebarNavigationItemProps {
  icon: string;
  text: string;
  onClick: () => void;
  isActive: boolean;
}

const SidebarNavigationItem: React.FC<SidebarNavigationItemProps> = ({
  icon,
  text,
  onClick,
  isActive,
}) => {
  const { t } = useTranslation();
  
  const itemStyles = isActive ? "bg-primary-grey-dark" : "hover:bg-primary-grey";

  return (
    <li>
      <button
        onClick={onClick}
        className={`focus:outline-none w-full text-center flex flex-col items-center p-2 border border-primary-green ${itemStyles}`}
        style={{ wordWrap: "break-word" }}
      >
        <img src={icon} alt={t(text)} className="h-6" />
        <span className="mt-2 text-xs" style={{ wordBreak: "break-word" }}>
          {t(text)}
        </span>
      </button>
    </li>
  );
};

export default SidebarNavigationItem;
