import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../app/store";
import { checkDoorFeatures } from "../utils";

type GlassSectionData = {
    "door-glass"?: string;
    "side-glasses"?: string;
    "transom-glass"?: string;
  };

export const usePrintQueryGlass = () => {
  const { t } = useTranslation();
  const configuration = useSelector((state: RootState) => state.configuration);
  const { hasSideGlass, hasTransom } = checkDoorFeatures(configuration.type);

  let glassSectionData: GlassSectionData = {};

  if (configuration["inner-glass"] !== null) {
    glassSectionData["door-glass"] =
      configuration["inner-glass"] === "default"
        ? `${t("glass")} ${configuration.model}`
        : t(`glass-${configuration["inner-glass"]}`);
  }

  if (hasSideGlass) {
    glassSectionData["side-glasses"] = t(
      `glass-${configuration["side-glass"]}`
    ).includes("glass")
      ? `${t("glass")} ${configuration["side-glass"]}`
      : t(`glass-${configuration["side-glass"]}`);
  }

  if (hasTransom) {
    glassSectionData["transom-glass"] = t(`glass-${configuration["transom-glass"]}`);
  }

  return glassSectionData;
};
