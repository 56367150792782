import { EquipmentItem } from "../../../../../types";

export const parapetProtectionItems: EquipmentItem[] = [
  {
    id: 1,
    src: "IP3.png",
    alt: "23-00-00476-1",
    label: "23-00-00476-1\nIP3",
  },
  {
    id: 2,
    src: "IP2.png",
    alt: "23-00-00476-2",
    label: "23-00-00476-2\nIP2",
  },
  {
    id: 3,
    src: "IP1.png",
    alt: "23-00-00476-3",
    label: "23-00-00476-3\nIP1",
  },
  {
    id: 4,
    src: "IP4.png",
    alt: "23-00-00476-4",
    label: "23-00-00476-4\nIP4",
  },
];
