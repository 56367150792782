import { useTranslation } from "react-i18next";

import Select from "../../../ui/Select";
import GlassGrid from "./GlassGrid";
import Selectable from "../../../ui/Selectable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setActiveDropDownItem } from "../../../../features/sidebar/sidebarSlice";
import Chip from "../../../ui/Chip";
import useEquipmentGlasses from "../../../../hooks/useEquipmentGlasses";

interface SelectOption {
  value: string;
  label: string;
}

const Glass = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chosenSelect = useSelector(
    (state: RootState) => state.sidebar.activeDropDownItem.glass
  );

  const {
    ornamentGlasses,
    decorativeSideGlasses,
    hasTransom,
    hasSideGlass,
    decorativeInnerGlassForModal,
    selectedDoorModel,
    hasInDoorGlass,
    selectedGlasses,
    dispatchSelectedGlass,
  } = useEquipmentGlasses();
  const selectOptions: SelectOption[] = [
    hasInDoorGlass ? { value: "doorglass", label: t("door-glass") } : null,
    hasSideGlass ? { value: "sideglasses", label: t("side-glasses") } : null,
    hasTransom ? { value: "skylight", label: t("skylight") } : null,
  ].filter(Boolean) as SelectOption[];

  const handleSelectGlass = (value: string) => {
    const sliceField =
      chosenSelect === "doorglass"
        ? "inner-glass"
        : chosenSelect === "sideglasses"
        ? "side-glass"
        : "transom-glass";

    dispatchSelectedGlass(sliceField, value);
  };

  const handleSelectChange = (value: string) => {
    dispatch(setActiveDropDownItem({ field: "glass", value }));
  };

  return selectOptions && selectOptions.length > 0 ? (
    <>
      <Chip classNames="mb-10">
        <h3 className=" text-2xl font-bold text-primary-green text-center">{t("choose-door-glass")}</h3>
      </Chip>

      <Select
        options={selectOptions}
        value={chosenSelect}
        onChange={handleSelectChange}
        classNames="mb-4"
      />

      <Chip classNames="mb-3 mt-10">
        <p className="font-semibold">{t("ornament-glass")}</p>
      </Chip>

      <GlassGrid
        items={ornamentGlasses}
        onSelect={(id) => handleSelectGlass(id)}
        selectedGlasses={selectedGlasses}
        chosenSelect={chosenSelect}
      />

      {chosenSelect === "doorglass" && decorativeInnerGlassForModal && (
        <>
          <Chip classNames="my-3 mt-10">
            <p className="font-semibold"> {t("decorative-glass")}</p>
          </Chip>

          <div className="text-center cursor-pointer hover:scale-105 transition-transform w-32">
            <Selectable
              isSelected={selectedGlasses.inner === "default"}
              onClick={() => handleSelectGlass("default")}
            >
              <img
                src={decorativeInnerGlassForModal}
                alt={selectedDoorModel}
                className="w-full"
              />

              <p>{`${t("glass")} ${selectedDoorModel}`}</p>
            </Selectable>
          </div>
        </>
      )}

      {chosenSelect === "sideglasses" && decorativeSideGlasses && (
        <>
          <Chip classNames="mb-3 mt-10">
            <p className="font-semibold"> {t("side-glass")}</p>
          </Chip>

          <GlassGrid
            items={decorativeSideGlasses}
            onSelect={(id) => handleSelectGlass(id)}
            selectedGlasses={selectedGlasses}
            chosenSelect={chosenSelect}
          />
        </>
      )}
    </>
  ) : (
    <p>This model doesn't have glass</p>
  );
};

export default Glass;
