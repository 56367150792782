import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dropdown from "../ui/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const AdditionalNavItems = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dropdownItems = [
    {
      key: "homePage",
      label: t("homePage"),
      action: () => navigate("/admin"),
    },
    {
      key: "users",
      label: t("users"),
      action: () => navigate("/admin/users"),
    },
    // {
    //   key: "configurator",
    //   label: t("configurator"),
    //   action: () => navigate("/"),
    // },
  ];

  return (
    <>
      <div className="hidden sm:flex gap-3">
        {dropdownItems.map((item) => (
          <div
            className="border rounded-xl py-1 px-2 transition duration-300 ease-in-out transform hover:underline hover:text-primary-light hover:border-primary-light  hover:shadow-lg cursor-pointer font-bold"
            key={item.key}
            onClick={item.action}
          >
            {item.label}
          </div>
        ))}
      </div>

      <div className="block sm:hidden">
        <Dropdown
          trigger={
            <FontAwesomeIcon
              icon={faBars}
              size="2x"
              className="cursor-pointer text-black hover:text-primary-green"
            />
          }
          items={dropdownItems}
          className="right-0 w-48 bg-translucent text-white shadow-lg rounded-xl"
        />
      </div>
    </>
  );
};

export default AdditionalNavItems;
