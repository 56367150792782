import React from "react";

import styles from "./EquipmentRows.module.css";
import { EquipmentItem } from "../../../../../types";
import Selectable from "../../../../ui/Selectable";
import RemoveItem from "../../../../ui/RemoveItem";

interface EquipmentRowsProps {
  items: EquipmentItem[];
  selectedItem: number | null;
  handleItemSelect: (id: number | null, name: string | null, imageName: string | null) => void;
  itemCanBeRemoved?: boolean
}

const EquipmentRows: React.FC<EquipmentRowsProps> = ({
  items,
  selectedItem,
  handleItemSelect,
  itemCanBeRemoved=false
}) => {
  const renderItem = (item: EquipmentItem) => (
    <Selectable
      isSelected={selectedItem === item.id}
      onClick={() => handleItemSelect(item.id, item.label, item.alt)}
    >
      <img
        className={styles.imageStyle}
        src={`${process.env.REACT_APP_API_URL}/storage/thumbnails/equipment/${item.src}`}
        alt={item.alt}
      />

      <p className={styles.textStyle}>{item.label}</p>
    </Selectable>
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="h-8">
        <RemoveItem
          isShowed={selectedItem !== null && itemCanBeRemoved}
          onClick={() => handleItemSelect(null, null, null)}
        />
      </div>

      {items.map((item, index) => (
        <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
      ))}
    </div>
  );
};

export default EquipmentRows;
