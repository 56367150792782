import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHouses } from "../../../../hooks/useHouses";
import Loading from "../../../ui/Loading";
import Error from "../../../ui/Error";
import {
  setHouseImageUrl,
  setSelectedHouseId,
} from "../../../../features/background/backgroundSlice";
import { RootState } from "../../../../app/store";
import Selectable from "../../../ui/Selectable";
import Chip from "../../../ui/Chip";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";

const Houses: React.FC<{ setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setIsSidebarOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { houses, isLoading, isError } = useHouses();
  const selectedHouseId = useSelector(
    (state: RootState) => state.background.selectedHouseId
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Briši localStorage kada se stranica osveži
  const handleBeforeUnload = () => {
    localStorage.removeItem("backgroundImage");
  };
  window.addEventListener("beforeunload", handleBeforeUnload);

  const localStorageImg = localStorage.getItem("backgroundImage");

  const addPicture = () => {
    fileInputRef.current?.click();
  }

  const savePicture = () => {
    setIsSidebarOpen(false); 
    setTimeout(() => {
      const mainComponent = document.querySelector("#app-content") as HTMLElement; 
      if (mainComponent) {
        html2canvas(mainComponent).then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = "algreen-screenshot.png"; 
          link.click();
        });
      }
    }, 0);
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        dispatch(setHouseImageUrl(imageUrl)); // Postavljanje slike kao pozadine
        localStorage.setItem("backgroundImage", imageUrl); // Čuvanje slike u localStorage
      };
      reader.readAsDataURL(file);
    }
    event.target.value = ''; // Resetovanje input-a
  }

  useEffect(() => {
    if (selectedHouseId !== null) {
      const selectedHouse = houses.find(
        (house) => house.id === selectedHouseId
      );
      if (localStorageImg) {
        dispatch(setHouseImageUrl(localStorageImg)
        );
        return;
      }
      if (selectedHouse) {
        dispatch(
          setHouseImageUrl(
            `${process.env.REACT_APP_API_URL}/${selectedHouse.image}`
          )
        );
      }
    }
  }, [selectedHouseId, houses, dispatch, localStorageImg]);

  const handleSelectHouse = (id: number, image: string) => {
    localStorage.removeItem("backgroundImage");
    const newSelectedHouseId = selectedHouseId === id ? null : id;
    dispatch(setSelectedHouseId(newSelectedHouseId));
    dispatch(
      setHouseImageUrl(
        newSelectedHouseId ? `${process.env.REACT_APP_API_URL}/${image}` : ""
      )
    );
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error message="Error loading houses" />;

  return (
    <>
      <Chip classNames="mb-10">
        <h3 className=" text-2xl font-bold text-primary-green text-center">{t("choose-door-house")}</h3>
      </Chip>

      <div className="mb-4 flex justify-around">
        <button className="bg-primary-green text-white px-4 py-2 rounded" onClick={addPicture}>Ubaci sliku</button>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <button className="bg-primary-green text-white px-4 py-2 rounded" onClick={savePicture}>Sacuvaj sliku</button>
      </div>

      <div className="grid grid-cols-1 gap-2">
        {houses.map((house) => (
          <Selectable
            key={house.id}
            isSelected={selectedHouseId === house.id}
            onClick={() => handleSelectHouse(house.id, house.image)}
            classNames="border border-primary-green"
          >
            <img
              src={`${process.env.REACT_APP_API_URL}/${house.image}`}
              alt={`House ${house.id}`}
              className="object-cover w-full h-full"
            />
          </Selectable>
        ))}
      </div>
    </>
  );
};

export default Houses;
