interface ChipProps {
  children: React.ReactNode;
  classNames?: string;
}

const Chip: React.FC<ChipProps> = ({ children, classNames }) => {
  return (
    <div
      className={`bg-primary-light text-black text-sm font-medium rounded-lg py-1 px-3 drop-shadow-md ${classNames}`}
    >
      {children}
    </div>
  );
};

export default Chip;
