import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { selectUserData } from "../features/user/userSlice";
import { useFetchWithAuth } from "./useFetchWithAuth";

interface FormData {
  username: string;
  phone: string;
  mobile_phone: string;
  city: string;
  address: string;
  zip_code: string;
  company_name: string;
  pib: string;
  social_number: string;
  giro_account: string;
  email: string;
  contact_person: string;
  logo: string | File;
}

const usePersonalInfoForm = () => {
  const { t } = useTranslation();
  const { fetchData } = useFetchWithAuth();
  const userData = useSelector(selectUserData);

  const [formData, setFormData] = useState<FormData>({
    username: "",
    phone: "",
    mobile_phone: "",
    city: "",
    address: "",
    zip_code: "",
    company_name: "",
    pib: "",
    social_number: "",
    giro_account: "",
    email: "",
    contact_person: "",
    logo: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        username: userData.username || "",
        phone: userData.phone || "",
        mobile_phone: userData.mobile_phone || "",
        city: userData.city || "",
        address: userData.address || "",
        zip_code: userData.zip_code || "",
        company_name: userData.company_name || "",
        pib: userData.pib || "",
        social_number: userData.social_number || "",
        giro_account: userData.giro_account || "",
        email: userData.email || "",
        contact_person: userData.contact_person || "",
        logo: userData.logo || "",
      });
    }
  }, [userData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (updatedFormData: FormData): Promise<boolean> => {
    try {
      const response = await fetchData(
        `${process.env.REACT_APP_API_URL}/api/update`,
        "PUT",
        updatedFormData
      );
      return !!response;
    } catch (error) {
      toast.error(t("auth-messages.update-failed"));
      return false;
    }
  };

  return { formData, handleInputChange, handleSubmit, setFormData };
};

export default usePersonalInfoForm;
