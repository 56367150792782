import React from "react";

import styles from "./EquipmentGrid.module.css";
import Selectable from "../../../../ui/Selectable";
import { EquipmentItem } from "../../../../../types";
import RemoveItem from "../../../../ui/RemoveItem";

interface EquipmentGridProps {
  items: EquipmentItem[];
  selectedItem: number | null;
  handleItemSelect: (id: number | null, name: string | null, imageName: string | null) => void;
  itemCanBeRemoved?: boolean;
}

const EquipmentGrid: React.FC<EquipmentGridProps> = ({
  items,
  selectedItem,
  handleItemSelect,
  itemCanBeRemoved = false,
}) => {
  const renderItem = (item: EquipmentItem) => (
    <Selectable
      isSelected={selectedItem === item.id}
      onClick={() => handleItemSelect(item.id, item.label, item.alt)}
      classNames="border border-primary-green h-36 justify-center"
    >
      <img
        className={styles.imageStyle}
        src={`${process.env.REACT_APP_API_URL}/storage/thumbnails/equipment/${item.src}`}
        alt={item.alt}
      />

      {item.label && (
        <p className={styles.textStyle}>
          {item.label.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      )}
    </Selectable>
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="h-8">
        <RemoveItem
          isShowed={selectedItem !== null && itemCanBeRemoved}
          onClick={() => handleItemSelect(null, null, null)}
        />
      </div>

      <div className={styles.gridContainer}>
        {items.map((item, index) => (
          <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default EquipmentGrid;
