import React, { FC, ChangeEvent, InputHTMLAttributes } from "react";

interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  ...props
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <label className="flex items-center space-x-3">
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring focus:ring-offset-0 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
        checked={checked}
        onChange={handleChange}
        {...props}
      />

      <span
        className={` text-gray-700 ${props.disabled ? "text-gray-400" : ""}`}
      >
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
