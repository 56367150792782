import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faFile, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import { toBase64 } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface InputImageProps {
  type?: string;
  initialImage?: string | undefined;
  name: string;
  required?: boolean;
  onFileChange: (base64Image: string | null) => void;
}

const InputImage: React.FC<InputImageProps> = ({
  type = "image",
  initialImage,
  name,
  required,
  onFileChange,
}) => {
  const { t } = useTranslation();
  const fileInputRef = React.createRef<HTMLInputElement>();

  const [localImage, setLocalImage] = useState<string | null>(
    initialImage || null
  );

  useEffect(() => {
    if (initialImage) {
      setLocalImage(initialImage);
    } else {
      setLocalImage(null);
    }
  }, [initialImage]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        const base64 = await toBase64(file);
        setLocalImage(base64);
        onFileChange(base64);
      } catch (error) {
        console.error("Error converting file to base64", error);
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = () => {
    setLocalImage(null);
    onFileChange(null);
  };

  return (
    <div className="flex flex-col">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
      />

      <Button
        variant="link"
        className="w-fit text-primary-light px-0 py-0"
        type="button"
        onClick={handleButtonClick}
        icon={faFile}
      >
        {localImage ? (
          <span>{t(`change-${type}`)}</span>
        ) : (
          <span>{t(`choose-${type}`)}</span>
        )}
        <span className="ml-1">
          {required && <span className="text-red-500">*</span>}
        </span>
      </Button>

      {localImage && (
        <div className="relative w-fit">
          <img
            src={
              localImage.startsWith("data:")
                ? localImage
                : `${process.env.REACT_APP_API_URL}/storage/${localImage}`
            }
            alt={name}
            className="h-20 w-20 object-cover p-1 border border-primary-green rounded-md"
          />
          <button
            type="button"
            onClick={handleRemoveImage}
            className="absolute top-2 right-2 transform translate-x-1/2 -translate-y-1/2 bg-danger hover:bg-danger-dark text-white rounded-full w-6 h-6 flex justify-center items-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      )}
    </div>
  );
};

export default InputImage;
