import { useState, useMemo } from "react";
import { Color, GroupedColors, ColorCategory } from "../types";
import { useSWRFetcher } from "../utils";

interface UseColorsReturn {
  colorsByCategory: GroupedColors | undefined;
  colors: Color[] | undefined;
  categories: ColorCategory[] | undefined;
  isLoading: boolean;
  isError: boolean;
  isMutating: boolean;
  deleteColor: (id: number) => Promise<void>;
  addOrUpdateColor: (
    id: number | null,
    colorData: Partial<Color>
  ) => Promise<void>;
  deleteCategory: (id: number) => Promise<void>;
  addOrUpdateCategory: (
    id: number | null,
    categoryData: Partial<ColorCategory>
  ) => Promise<void>;
}

export const useColors = (colorType?: "panel" | "frame"): UseColorsReturn => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isMutating, setIsMutating] = useState(false);

  const {
    data: colorsData,
    error: colorsError,
    mutate: mutateColors,
    isLoading: colorsLoading,
  } = useSWRFetcher<Color[]>(`${API_URL}/api/colors`, undefined, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const {
    data: categoriesData,
    error: categoriesError,
    mutate: mutateCategories,
    isLoading: categoriesLoading,
  } = useSWRFetcher<ColorCategory[]>(
    `${API_URL}/api/color-categories`,
    undefined,
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  const colorsByCategory: GroupedColors = useMemo(() => {
    const result: GroupedColors = {};
    if (colorsData && categoriesData) {
      colorsData.forEach((color) => {
        const category = color.color_category;
        if (category) {
          const shouldInclude =
            !colorType ||
            (colorType === "panel" && category.show_in_panel) ||
            (colorType === "frame" && category.show_in_stock);

          if (shouldInclude) {
            const categoryName =
              category.color_category_name || "Uncategorized";
            if (!result[categoryName]) {
              result[categoryName] = [];
            }
            result[categoryName].push(color);
          }
        }
      });
    }
    return result;
  }, [colorsData, categoriesData, colorType]);

  const deleteColor = async (id: number): Promise<void> => {
    setIsMutating(true);
    try {
      await fetch(`${API_URL}/api/colors/${id}`, {
        method: "DELETE",
      });
      mutateColors();
    } catch (error) {
      throw error;
    } finally {
      setIsMutating(false);
    }
  };

  const addOrUpdateColor = async (
    id: number | null,
    colorData: Partial<Color>
  ): Promise<void> => {
    setIsMutating(true);
    const method = id ? "PUT" : "POST";
    const url = id ? `${API_URL}/api/colors/${id}` : `${API_URL}/api/colors`;

    try {
      await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(colorData),
      });
      mutateColors();
    } catch (error) {
      throw error;
    } finally {
      setIsMutating(false);
    }
  };

  const deleteCategory = async (id: number): Promise<void> => {
    setIsMutating(true);
    try {
      await fetch(`${API_URL}/api/color-categories/${id}`, {
        method: "DELETE",
      });
      mutateCategories();
    } catch (error) {
      throw error;
    } finally {
      setIsMutating(false);
    }
  };

  const addOrUpdateCategory = async (
    id: number | null,
    categoryData: Partial<ColorCategory>
  ): Promise<void> => {
    setIsMutating(true);
    const method = id ? "PUT" : "POST";
    const url = id
      ? `${API_URL}/api/color-categories/${id}`
      : `${API_URL}/api/color-categories`;

    try {
      await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(categoryData),
      });
      mutateCategories();
    } catch (error) {
      throw error;
    } finally {
      setIsMutating(false);
    }
  };

  return {
    colorsByCategory,
    colors: colorsData,
    categories: categoriesData,
    isLoading: colorsLoading || categoriesLoading,
    isError: !!colorsError || !!categoriesError,
    isMutating,
    deleteColor,
    addOrUpdateColor,
    deleteCategory,
    addOrUpdateCategory,
  };
};
