import React, { ReactNode, MouseEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

type SelectableProps = {
  isSelected: boolean;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  classNames?: string;
};

const Selectable: React.FC<SelectableProps> = ({
  isSelected,
  children,
  onClick,
  classNames = "",
}) => {
  const selectedClass = isSelected ? "bg-primary-grey-light" : "hover:bg-primary-grey-lightest";
  const textClass = isSelected ? "text-white" : "text-black"; 

  const iconWrapperStyle = `
    absolute inset-0 flex justify-center items-center
    bg-primary-grey-light bg-opacity-80 rounded-full h-6 w-6
  `;

  return (
    <div
      className={`relative cursor-pointer rounded-lg p-2 flex flex-col items-center transform transition-transform duration-300 border border-primary-green ${selectedClass} ${textClass} ${classNames}`}
      onClick={onClick}
    >
      {children}

      {isSelected && (
        <div className={iconWrapperStyle}>
          <FontAwesomeIcon icon={faCheck} className="z-10 text-primary-light" />
        </div>
      )}
    </div>
  );
};

export default Selectable;
