import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface LoadingProps {
  classNames?: string;
  variant?: "primary" | "primary-light";
}

const Loading = ({ classNames, variant = "primary" }: LoadingProps) => {
  const colorVariants = {
    primary: "text-primary",
    "primary-light": "text-primary-light",
  };

  return (
    <div className={`flex justify-center items-center h-full ${classNames}`}>
      <FontAwesomeIcon
        icon={faSpinner}
        className={`${colorVariants[variant]} animate-spin rounded-full p-2`}
        aria-hidden="true"
      />
    </div>
  );
};

export default Loading;
